import React, { useEffect, useState } from "react";
import { addFile, convertImages } from "../../services/Storage";
import { toArrayList, toArrayListKey, inArrayValueByKey } from "../../utilities/array";


import UiInputText from "../inputs/UiInputText";
import UiModal from "./UiModal";

import "./UiModalDeal.scss";
import "./UiModalUser.scss";
import UiDropDown from "../inputs/UiDropDown";
import UiTextInputMasked from "../inputs/UiTextInputMasked";
import UiCardAddPhoto from "../cards/UiCardAddPhoto";
import UiButtonColor from "../button/UiButtonColor";
import Env from "../../services/Env";
import UserConstant from "../../constants/UserConstant";

function UiModalPromocode(props) {
  const [name, setName] = useState(null);
  const [value, setValue] = useState(10);
 
  const [imageUrl, setImageUrl] = useState("");
  const [load, setLoad] = useState(false);

 

  useEffect(() => {
    if (props.data != null) {
      setName(props.data.name);
      setValue(props.data.value); 

    } else {
      setName(null);
      setValue(null); 
    }
  }, [props.data]);


  return (
    <UiModal name="modal" show={props.modalActive}>
      <div className="modal-deal">
        <div className="modal-deal-wrap">
          {/**
           * modal left side - static
           */}
          <div className="modal-deal-about add">
            {/**
             * modal title
             */}
            <div className="modal-deal-about-title">
              <div className="modal-deal-about-title-wrap">
                <h3>
                  {props.data ? "Информация о промокоде" : "Создать промокод"}
                </h3>
              </div>
              <div className="modal-deal-about-buttons"> </div>
            </div>
            {/**
             * modal notifications + msgs
             */}
            <div className="modal-deal-notes">
              <div className="modal-deal-notes-list-wrap">
                <div className="modal-deal-info">
                  <div className="modal-deal-info-form">
                    <UiInputText
                      label="Название промокода"
                      value={name}
                      onChange={(val) => setName(val)}
                    />
                  </div>
                  <div className="modal-deal-info-form">
                    <UiInputText
                      label="%"
                      value={value}
                      onChange={(val) => setValue(val)}
                    />
                  </div>


 

                  <div className="modal-deal-info-form">
                    {props.data ? (
                      <UiButtonColor
                        color="primary"
                        text="Обновить"
                        small={false}
                        onClick={() => {
                          props.onEdit({
                            id: props.data.id,
                            title: name,
                            value: value,
                            is_del: 0
                          });
                        }}
                      />
                    ) : (
                      <UiButtonColor
                        color="primary"
                        text="Создать"
                        small={false}
                        onClick={() => {
                          props.onCreate({
                            title: name,
                            value: value,
                          });
                        }}
                      />
                    )}
                  </div>

                  <div className="modal-deal-info-form">
                    <UiButtonColor
                      color="red"
                      text="Закрыть"
                      small={false}
                      onClick={() => {
                         props.modalClose()
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </UiModal>
  );
}

export default UiModalPromocode;
