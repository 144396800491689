import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import {
  getPromocodeList as getPromocodeListAction,
  addPromocode as addPromocodeAction,
  update as updateAction,
} from "../../redux/modules/promocode";

import { convertImages, fromArrayListKey } from "../../utilities/array";

import UiInputSearch from "../../components/inputs/UiInputSearch";
import UiButtonColor from "../../components/button/UiButtonColor";
import UiModalProductAdd from "../../components/modals/UiModalProductAdd";
import UiPageText from "../../components/text/UiPageText";
import UiTableAdaptive from "../../components/table/UiTableAdaptive";
import UiModalPromocode from "../../components/modals/UiModalPromocode";

import "./MaterialsStyle.scss";
import Env from "../../services/Env";
import { storeData } from "../../services/Storage";

function PromocodesPage(props) {
  const { id } = useParams();
  const navigate = useNavigate();
  const [modalActive, setModalActive] = useState(false);
  const [searchLine, setSearchLine] = useState(null);
  const [itemData, setItemData] = useState(null);

  const clientsTitles = [ 
    { sort: false, title: "Промокод" },  
    { sort: false, title: "Процент" },
 
    { sort: false, title: "Удалить" },
  ];
  const [tableListOrders, setTableListOrders] = useState([]);

  useEffect(() => {
    props.getPromocodeList();
  }, []);

  useEffect(() => {
    let arr = [];
    props.promocode.list.map((item) => {
      let images = convertImages(item.imgs);
      item.images = images;
      arr.push([
 
        { text: item.title },
        { text: `${item.value}%` }, 
         
        {
          button:  "delete",
          onClick: () => {
            let _item = item;
            _item.is_del = 1;
            props.update(_item);
          },
        },
      ]);
    });
    setTableListOrders(arr);
  }, [props.promocode.list]);

  return (
    <div id="page" className="home-page">
      <section className="home-info ui-grid">
        <div className="home-info-text">
          <UiPageText title="Список промокодов" />
        </div>
        <div className="home-info-button">
          <UiButtonColor
            color="indigo"
            text="Добавить"
            small={true}
            onClick={() => {
             setModalActive(true)
            }}
          />
        </div>
      </section>
      <section className="page-content">
        <div className="page-search">
          <UiInputSearch
            label="Список"
            placeholder="Поиск:"
            onChange={(val) => setSearchLine(val)}
          />
        </div>

        <UiTableAdaptive
          titleList={clientsTitles}
          grid={clientsTitles.length}
          tableList={tableListOrders}
          search={searchLine}
        />
      </section>

       {/* modal */}
       <UiModalPromocode
        modalActive={modalActive}
        modalClose={() => setModalActive(false)}
        data={itemData}
        onCreate={(data) => {
          props.addPromocode(data).then((res) => {
            if (res) {
              setModalActive(false);
              props.getPromocodeList();
            }
          });
        }}
        onEdit={(data) => {
          props.editWorker(data).then((res) => {
            if (res) {
              setModalActive(false);
              props.getPromocodeList();
            }
          });
        }}
      />

    </div>
  );
}

export default connect(
  ({ promocode }) => ({
    promocode,
  }),
  {
    addPromocode: addPromocodeAction,
    getPromocodeList: getPromocodeListAction,
    update: updateAction,
  }
)(PromocodesPage);
